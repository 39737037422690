import React, { useState, useEffect } from 'react';
import { AlertCircle, Zap } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

// Import the courses data directly
import coursesData from './courses.json';

const QuestionGenerator = () => {
  const [format, setFormat] = useState('blooket');
  const [numQuestions, setNumQuestions] = useState(5);
  const [studentAge, setStudentAge] = useState('');
  const [topic, setTopic] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [stars, setStars] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');

  useEffect(() => {
    const generateStars = () => {
      const newStars = [];
      for (let i = 0; i < 100; i++) {
        newStars.push({
          left: `${Math.random() * 100}%`,
          top: `${Math.random() * 100}%`,
          animationDuration: `${Math.random() * 3 + 2}s`,
        });
      }
      setStars(newStars);
    };
    generateStars();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setResult(null);

    try {
      const response = await fetch('https://api.hauthomelabs.org/generate-questions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          format, 
          numQuestions, 
          studentAge, 
          topic,
          courseId: format === 'canvas' && selectedCourse ? selectedCourse : null 
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to generate questions');
      }

      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        const data = await response.json();
        setResult({ message: data.message || 'Questions generated and uploaded successfully!' });
      } else {
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        if (format === "blooket") {
          a.download = `${topic}.csv`;
        } else if (format === "canvas" && !selectedCourse) {
          a.download = `${topic}.zip`;
        }
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(downloadUrl);
        setResult({ message: format === 'canvas' && selectedCourse ? 
          'Questions have been uploaded to Canvas!' : 
          'Your questions have been downloaded!' 
        });
      }
    } catch (err) {
      console.error('Error details:', err);
      setError(`An error occurred: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-indigo-900 to-purple-900 flex items-center justify-center px-4 relative overflow-hidden">
      {/* Stars background remains the same */}
      {stars.map((star, index) => (
        <div
          key={index}
          className="absolute bg-white rounded-full w-1 h-1 animate-twinkle"
          style={{
            left: star.left,
            top: star.top,
            animationDuration: star.animationDuration,
          }}
        />
      ))}
      
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-md bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl shadow-2xl p-8 relative z-10"
      >
        <h1 className="text-4xl font-bold mb-6 text-center text-white">
          <Zap className="inline-block mr-2 text-yellow-400" />
          Epic Question Generator
        </h1>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-200 mb-2">Format</label>
            <div className="flex space-x-4">
              <button
                type="button"
                onClick={() => {
                  setFormat('blooket');
                  setSelectedCourse('');
                }}
                className={`flex-1 py-2 px-4 rounded-md ${
                  format === 'blooket'
                    ? 'bg-indigo-600 text-white'
                    : 'bg-gray-200 text-gray-800'
                }`}
              >
                Blooket
              </button>
              <button
                type="button"
                onClick={() => setFormat('canvas')}
                className={`flex-1 py-2 px-4 rounded-md ${
                  format === 'canvas'
                    ? 'bg-indigo-600 text-white'
                    : 'bg-gray-200 text-gray-800'
                }`}
              >
                Canvas
              </button>
            </div>
          </div>

          {format === 'canvas' && (
  <div>
    <label className="block text-sm font-medium text-gray-200">Canvas Course</label>
    <select
      value={selectedCourse}
      onChange={(e) => setSelectedCourse(e.target.value)}
      className="mt-1 block w-full rounded-md border-gray-300 bg-opacity-20 bg-white text-white placeholder-gray-400 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
      style={{ color: selectedCourse ? 'white' : 'black' }} // Text inside the box is white unless the default option is selected
    >
      <option value="" style={{ color: 'black' }}>Download Only</option>
      {coursesData.courses.map((course) => (
        <option 
          key={course.id} 
          value={course.id} 
          style={{ color: 'black' }} // Dropdown options text color is black
        >
          {course.name}
        </option>
      ))}
    </select>
  </div>
)}

          {/* Rest of the form remains the same */}
          <div>
            <label className="block text-sm font-medium text-gray-200">Number of Questions</label>
            <input
              type="number"
              value={numQuestions}
              onChange={(e) => setNumQuestions(e.target.value)}
              min="1"
              className="mt-1 block w-full rounded-md border-gray-300 bg-opacity-20 bg-white text-white placeholder-gray-400 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-200">Student Age</label>
            <input
              type="text"
              value={studentAge}
              onChange={(e) => setStudentAge(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 bg-opacity-20 bg-white text-white placeholder-gray-400 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-200">Topic</label>
            <input
              type="text"
              value={topic}
              onChange={(e) => setTopic(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 bg-opacity-20 bg-white text-white placeholder-gray-400 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <button
            type="submit"
            disabled={isLoading}
            className={`w-full py-2 px-4 rounded-md ${
              isLoading ? 'bg-gray-500' : 'bg-indigo-600 hover:bg-indigo-700'
            } text-white`}
          >
            {isLoading ? 'Generating...' : 'Generate Questions'}
          </button>
        </form>
        
        {error && (
          <div className="mt-4">
            <div className="flex items-center text-red-500">
              <AlertCircle className="mr-2" />
              <span>{error}</span>
            </div>
          </div>
        )}
        {result && (
          <div className="mt-4">
            <div className="flex items-center text-green-500">
              <AlertCircle className="mr-2" />
              <span>{result.message}</span>
            </div>
          </div>
        )}
      </motion.div>
    </div>
  );
};

export default QuestionGenerator;